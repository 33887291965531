import CustomDrawerPype from "../components/drawer/CustomDrawerPype";
import CustomModalPype from "../components/modal/CustomModalPype";
import Link from "../Link";
import { handleStates } from "../reducer/Actions";
import { useMLv2Context } from "../reducer/MLV2Provider";
import CampaignFolder from "./CampaignFolder";
import ActionList from "./modalScreens/ActionList";
import AddFolder from "./modalScreens/AddFolder";
import AddToFolder from "./modalScreens/AddToFolder";
import { confirmAlert } from 'react-confirm-alert';

const ModalImport = () => {
    const {state, dispatch} = useMLv2Context();

    const handleAddToFolderModal = (status) => {
        if(state.submittingFolder || state.deletingFolder){
            return
        }
        if(!status){
            dispatch(handleStates({isShowFolderCreate: status, editFolderData: null, editFolderIndex: null}))
        }else{
            dispatch(handleStates({isShowFolderCreate: status}))
        }
    }
    const handleCreateNewCampaignModal = (status) => {
        dispatch(handleStates({isShowCampaignCreate: status}))
    }
    const handleAddContactModal = (status) => {
        if(status){
            dispatch(handleStates({isShowAddContact: status}))
        }
        else{
            dispatch(handleStates({isShowAddContact: status, selectedCampaignForAction: {}, selectedCampaignForActionIndex: null}))
        }
    }
    const handleShareCampaignModal = (status) => {
        if(status){
            dispatch(handleStates({isShowShare: status}))
        }
        else{
            dispatch(handleStates({isShowShare: status, selectedCampaignForAction: {}, selectedCampaignForActionIndex: null}))
        }
    }
    const handleFolderViewDrawer = (status) => {
        dispatch(handleStates({isShowFolderDrawer: status}))
    }

    /* start campaign */
    const startCampaignStatus = (fields = {}) => {
        dispatch(handleStates({isProcessingStatus: true}))
        let data = {
            campaignId : state.selectedCampaignForAction?.id,
            campaign_id : state.selectedCampaignForAction?.id,
            prevent_previous_followup: 0,
            sent_immediate_after_resume: 1
        }
        data = {...data, ...fields}
        Link.API_LIST.campaignStart(data)
        .then(res => {
            let response = res.data;
            if(response.status === "error"){
                /* change switch again ! */
                dispatch(handleStates({isProcessingStatus: false, resetSwitch: true}))
                if(response.html && response.html === "Drip Campaign Status Is Not Pause"){
                    window.showNotification("error", response.html);
                }else if (response.data.html){
                    let regularRemove = /[0-9]/g
                    window.showNotification("error", response.html.replace(regularRemove,''));
                }
            }
            else{
                dispatch(handleStates({isShowCampaignStart: false, isProcessingStatus: false, resetBlock: true}))
                confirmAlert({
                    childrenElement: ()=>{ return <Link.CampaignActionNoticeBoard />},
                    buttons: [
                        {
                            label: 'Close',
                            onClick: () => {
                                return;
                            },
                            className: "button-modify",
                        }
                    ],
                    overlayClassName: "alert-button-modify",
                })
            }
            dispatch(handleStates({ selectedCampaignForAction: {}, selectedCampaignForActionIndex: null, actionList: [], resetBlock: true}))
        })
        .catch(error => {
            dispatch(handleStates({isProcessingStatus: false, resetBlock: true}))
        })
    }
    return (
        <>
            <CustomDrawerPype 
                isShow={state.isShowFolderCreate}
                hanldeDrawer={handleAddToFolderModal}
                customWidthClass="add_campaign_folder_mv2_wr"
                renderChild={() => {
                    return (<AddFolder reducer={{state, dispatch}} onClose={handleAddToFolderModal} />)
                }}
                anchor={'right'}
            />
            
            {(window.screen.width > 768 && window.screen.width <=1240) &&
            <CustomDrawerPype 
                isShow={state.isShowFolderDrawer}
                hanldeDrawer={handleFolderViewDrawer}
                customWidthClass="campaign_folder_view_drawer_mv2_wr"
                renderChild={() => <CampaignFolder className="cfvd_mv2w_container_drawer" />}
                anchor={'left'}
                width={'280px'}
            />
            }

            <CustomModalPype 
                isShow={state.isShowAddToFolder}
                hanldeModal={(status) => {
                    if(status){
                        dispatch(handleStates({isShowAddToFolder: status}))
                    }
                    else{
                        dispatch(handleStates({isShowAddToFolder: status, selectedCampaignForAction: {}, selectedCampaignForActionIndex: null}))
                    }
                }}
                component={(
                    <AddToFolder 
                        handleClose={(status) => {
                            if(status){
                                dispatch(handleStates({isShowAddToFolder: status}))
                            }
                            else{
                                dispatch(handleStates({isShowAddToFolder: status, selectedCampaignForAction: {}, selectedCampaignForActionIndex: null}))
                            }
                        }}
                    />
                )}
            />

            <CustomModalPype 
                isShow={state.isShowActionList}
                hanldeModal={(status) => {
                    if(status){
                        dispatch(handleStates({isShowActionList: status}))
                    }
                    else{
                        dispatch(handleStates({isShowActionList: status, selectedCampaignForAction: {}, selectedCampaignForActionIndex: null, actionList: []}))
                    }
                }}
                component={(
                    <ActionList 
                        handleClose={(status, redirect = false, startCampaign = false) => {
                            if(redirect){
                                if(startCampaign){
                                    /* call start campaign here */
                                    dispatch(handleStates({isShowActionList: false, actionList: [], resetBlock: true}))
                                    startCampaignStatus()
                                }else{
                                    dispatch(handleStates({isShowActionList: false, isShowCampaignStart: true, actionList: [], resetBlock: true}))
                                }
                            }
                            else{
                                if(status){
                                    dispatch(handleStates({isShowActionList: status, resetBlock: true, actionList: []}))
                                }
                                else{
                                    dispatch(handleStates({isShowActionList: status, selectedCampaignForAction: {}, selectedCampaignForActionIndex: null, actionList: [], resetBlock: true}))
                                }
                            }
                        }}
                        closeOnActionClick={(e, reason) => {
                            if(!state.gettingActionList){
                                if(reason !== undefined && reason != null && reason !== "" && reason === 'Campaign is already running !'){
                                    dispatch(handleStates({isShowActionList: false, actionList: []}))
                                }
                                else{
                                    dispatch(handleStates({isShowActionList: false, resetSwitch: true, actionList: []}))
                                }
                            }
                        }}
                    />
                )}
                blockBackdrop={true}
            />

            {state.isShowCampaignCreate &&
            <Link.NEW_CAMPAIGN_MODAL 
                handleAddNewCampaignModal={handleCreateNewCampaignModal}
                open={state.isShowCampaignCreate}
                close={() => handleCreateNewCampaignModal(false)}
                folder={state.selectedFolder}
                privacyLevel={state.selectedTabOption}
            />
            }

            {state.isShowAddContact &&
            <Link.ADD_CONTACT_MODAL 
                handleAddNewContactModal={handleAddContactModal} 
                campaign={state.selectedCampaignForAction} 
                open={state.isShowAddContact}
                onClose={() => handleAddContactModal(false)}
            />
            }

            {state.isShowShare &&
            <Link.SHARE_CAMPAIGN_MODAL 
                handleSharePopUpModal={handleShareCampaignModal} 
                campaign={state.selectedCampaignForAction} 
                open={state.isShowShare}
                onClose={() => handleShareCampaignModal(false)}
            />
            }
            
            {state.isShowCampaignStart &&
            <Link.CAMPAIGN_START_MODAL
                campaign={state.selectedCampaignForAction} 
                totalContact={state.selectedCampaignForAction.stats == null ? 0 : state.selectedCampaignForAction.stats.total_contact}
                isLoading={state.isProcessingStatus}
                handleStartCampaign={startCampaignStatus}
                open={state.isShowCampaignStart}
                onClose={() => dispatch(handleStates({isShowCampaignStart: false, resetSwitch: true}))}
            />
            }
        </>
    );
}
export default ModalImport;