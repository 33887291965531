import { MLV2Provider } from "./reducer/MLV2Provider";
import "./css/style.css";
// import TopHeader from "./screens/TopHeader";
import { useEffect } from "react";
import CampaignBody from "./screensNewCampaign/CampaignBody";
import ModalImport from "./screensNewCampaign/ModalImport";

export const setCookieCampaign = (cname, cvalue) => {
    var d = new Date();
    d.setTime(d.getTime() + (2 * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
export const getCookieCampaign = cname => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        // eslint-disable-next-line eqeqeq
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        // eslint-disable-next-line eqeqeq
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

const MarketingListNew = () => {
    useEffect(() => {
        // eslint-disable-next-line no-extend-native
        String.prototype.showLinkTag = function(remove = false){
            let link = this;
            let element_exist = document.getElementById("meis_hyper_tag_show_link")
            if(element_exist){
                if(remove){
                    element_exist.classList.add('hide_meishtsl')
                    element_exist.innerText = "";
                }
                else{
                    element_exist.innerText = window.location.origin + link;
                    element_exist.classList.remove('hide_meishtsl')
                }
                if(link === ""){
                    element_exist.classList.add('hide_meishtsl')
                }
            }else{
                let container = document.createElement('span')
                container.className = "meis_hyper_tag_show_link"
                container.id = "meis_hyper_tag_show_link";
                if(remove){
                    container.classList.add('hide_meishtsl')
                    container.innerText = "";
                }
                else{
                    container.innerText = window.location.origin + link;
                    container.classList.remove('hide_meishtsl')
                }
                if(link === ""){
                    element_exist.classList.add('hide_meishtsl')
                }
                document.body.appendChild(container)
            }
            document.body.style.position = "relative";
            return true;
        }
        if (window.setCollapsedMenu) {
            window.setCollapsedMenu(true);
        }
    }, []);
    return (
        <MLV2Provider>
            <div className="marketing_list_v2_container">
                {/* <TopHeader /> */}
                <CampaignBody />
                {/* all modals will be import here */}
                <ModalImport />
                {/* <Drag /> */}
            </div>
        </MLV2Provider>
    );
}
export default MarketingListNew;

const Drag = () => {

    useEffect(() => {
        document.getElementById("drag-coveredup").addEventListener("dragstart", function(e) {
            // var crt = this.cloneNode(true);
            // crt.style.backgroundColor = "red";
            // crt.style.position = "absolute"; crt.style.top = "0px"; crt.style.right = "0px";
            // document.body.appendChild(crt);

            // let view_item = document.getElementById("copy_drag")
            // console.log(view_item)

            // let ele = document.createElement('div')
            // ele.innerText = "after drag"
            // ele.className = "copy_drag need_to_view"

            // e.dataTransfer.setDragImage(view_item, 0, 0);



            // var elem = document.createElement("div");
            // elem.id = "drag-ghost";
            // elem.className = "copy_drag need_to_view"
            // elem.innerText = "Dragging";
            // elem.style.position = "absolute";
            // elem.style.top = "-1000px";


            let from_div = document.getElementById("copy_drag");
            let elem = from_div.cloneNode(true);
            elem.id = "drag-ghost";
            elem.className = "copy_drag need_to_view"
            // elem.innerText = "Dragging";
            elem.style.position = "absolute";
            elem.style.top = "-1000px";

            document.body.appendChild(elem);
            e.dataTransfer.setDragImage(elem, 0, 0);


        }, false);
    }, [])
    
    // const startEvent = (e) => {
    //     var crt = this.cloneNode(true);
    //     crt.style.backgroundColor = "red";
    //     crt.style.position = "absolute"; crt.style.top = "0px"; crt.style.right = "0px";
    //     document.body.appendChild(crt);
    //     e.dataTransfer.setDragImage(crt, 0, 0);
    // }

    // document.addEventListener("dragend", function(e) {

    //     var ghost = document.getElementById("drag-ghost");
    //     if (ghost.parentNode) {
    //       ghost.parentNode.removeChild(ghost);
    //     }
      
    //   }, false);

    return (
        <div className="drag_container">

            <div 
                className="dc_each"
                // onDragStart={startEvent}
                draggable={true}
                id="drag-coveredup"
            >
                
                <div>Hello</div>
            </div>

            <div className="copy_drag" id="copy_drag">
                after drag
            </div>
            
        </div>
    );
}