import { makeStyles } from "@material-ui/core";

export const BroadCastTagStyle = makeStyles((theme) => ({
    dataContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      background: "#fff",
      borderRadius: "4px",
      padding: "10px 15px",
      margin: "5px",
    },
    dataList: {
      background: "#fff",
      borderRadius: "4px",
      padding: "10px 20px",
      margin: "10px",
    },
    noTag: {
      color: "red",
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      padding: "50px",
    },
    search: {
      marginTop: "10px",
      marginBottom: "10px",
      marginRight: "5px",
      marginLeft: "5px",
    },
  }));