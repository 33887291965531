/* eslint-disable eqeqeq */
import { Chip, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useState } from "react";
import BasicInput from "../../Common/BasicInput/BasicInput";
import { BroadCastTagStyle } from "./BroadCastTagStyle";
const BroadcastTag = (props) => {
  const [tagIds, setTagIds] = useState([]);
  const [search, setSearch] = useState("");
  const classes = BroadCastTagStyle();
  const onChangeList = (isChecked, id) => {
    let idsArray = [...tagIds];
    if (isChecked) {
      idsArray.push(id);
    } else {
      idsArray = idsArray.filter((e) => e !== id);
    }
    setTagIds(idsArray);
    props.changeIds(idsArray);
  };

  const renderList = () => {
    let filterArr;
    if (search !== "") {
      filterArr = props.tags.filter(function (obj) {
        return obj.title.toUpperCase().indexOf(search.toUpperCase()) !== -1;
      });
    } else {
      filterArr = props.tags;
    }

    if (filterArr.length == 0) {
      return (
        <div>
          <p className={classes.noTag}> No tag found !</p>
        </div>
      );
    }

    return filterArr.map((tag, index) => {
      return (
        <>
          <div className={classes.dataContainer}>
            <div>
              <label key={index}>
                <input
                  type="checkbox"
                  onChange={(e) => onChangeList(e.target.checked, tag.value)}
                  checked={tagIds.includes(tag.value)}
                  className="filled-in"
                />
                <span className="campaingName">{tag.title}</span>
              </label>
            </div>
            <div>
              <Chip
                label={tag.privacy_level}
                style={{
                  backgroundColor: tag.color || "#346fef",
                  color: "#fff",
                  fontWeight: "600",
                }}
              />
            </div>
          </div>
        </>
      );
    });
  };
  return (
    <div>
      <div className="tabBody">
        <div className={classes.search}>
          <BasicInput
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search Existing Tag"
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon style={{ color: "#656565" }} />
              </InputAdornment>
            }
          />
        </div>
        <div>{renderList()}</div>

        {/* <TimeSetting user={userInfo} timeSettingValue={props.timeSettingValue}/>
                <div className="d-flex justify-content-start senderEmailWrapper"> 
                <span>Sender {props.fromText !== undefined ? props.fromText : 'Phone Number'}: </span>
                <Select className="globalFormControlItem" value={props.selectedFrom} onChange={(e) => props.onChangeFrom(e.target.value)}>
                    {
                        props.froms.map((elem,index) => {
                            return <MenuItem className="menuitem-li-hover-color-with-border" key={index} value={elem.id}>{elem.value}</MenuItem>
                        })
                    }
                </Select>
                </div> */}
        {/* <Select value={props.selectedFrom} onChange={(e) => props.onChangeFrom(e.target.value)}>
                    {
                        props.froms.map((elem,index) => {
                            return <MenuItem key={index} value={elem.id}>{elem.value}</MenuItem>
                        })
                    }
                </Select> */}
      </div>
    </div>
  );
};

export default BroadcastTag;
