import { useMLv2Context } from "../../reducer/MLV2Provider";
import { Icons } from "../../helpers/Icons";
import CustomMenuPype from "../../components/custom-menu/CustomMenuPype";
import { handlePageNumber, handleStates } from "../../reducer/Actions";
import useDelayCallback from "../../hanlders/hooks/useDelayCallback";
import { useRef, useState } from "react";
import { useIsMounted } from "../../hanlders/hooks/IsMounted";
import { setCookieCampaign } from "../..";
const CampaignPagination = () => {
    const inputRef = useRef();
    const isMounted = useIsMounted()
    const {state, dispatch} = useMLv2Context();
    const [search, setSearch] = useState(state.currentPage);
    useDelayCallback(() => {
        if(isMounted){
            if(search.trim() !== ""){
                if(/^\d+$/.test(search)){
                    if(parseInt(search) === state.currentPage){
                        dispatch(handleStates({refreshList: true}))
                    }
                    else if(search <= Math.ceil((state.totalRecords / state.perPage))){
                        dispatch(handlePageNumber(parseInt(search)))
                    }
                }
            }
        }
    }, [search])
    useDelayCallback(() => {
        if(isMounted){
            if(inputRef.current.value !== state.currentPage){
                inputRef.current.value = state.currentPage;
            }
        }
    }, [state.currentPage])
    const onChangePage = (event) => {
        setSearch(event.target.value)
    }
    const handleStepChange = (type = 'next') => {
        if(type === 'next'){
            if(state.currentPage >= Math.ceil((state.totalRecords / state.perPage))){
                return
            }
            else{
                dispatch(handlePageNumber(state.currentPage + 1))
                inputRef.current.value = state.currentPage + 1
            }
        }
        else if(type === 'back'){
            if(state.currentPage <= 1){
                return
            }
            else{
                dispatch(handlePageNumber(state.currentPage - 1))
                inputRef.current.value = state.currentPage - 1
            }
        }
    }
    return (
        <div className="mlvc_ecb_sc_pagination">
            <div className="mlvc_ecb_sc_pagination_left">
                <span>Rows per page:</span>
                <div>
                    <CustomMenuPype
                        wrapperClass="mlvc_row_per_page_dropdown"
                        popup= {{
                            placement: 'center',
                            list: [
                                {label: '10', value: 10},
                                {label: '20', value: 20},
                                {label: '30', value: 30},
                                {label: '50', value: 50},
                                {label: '100', value: 100},
                            ],
                            onClick: (item) => {
                                if(item.value === state.perPage){
                                    return;
                                }
                                dispatch(handleStates({perPage: item.value, gettingList: true, currentPage: 1,isCampaignListFiltered: true}))
                                setCookieCampaign(
                                    'campaign_filter', 
                                    JSON.stringify({
                                        search: state.searchText,
                                        perPage: item.value 
                                    })
                                )
                            },
                            height: '200px',
                            width: '80px',
                            listStyle: {
                                justifyContent: 'center'
                            }
                        }}
                        label= {{
                            renderCustom: (
                                <div className="campaign_list_perpage_body">
                                    <span className="clpb_label">{state.perPage}</span>
                                    <span className="clpb_icon">{Icons.arrow_down}</span>
                                </div>
                            )
                        }}
                    />
                </div>
            </div>
            <div className="mlvc_ecb_sc_pagination_right">
                {state.totalRecords !== 0 &&
                <div className="mlvc_ecb_sc_pr_wr">
                    <span className={`mlvc_ecb_sc_pr_wr_item mlvc_ecb_sc_pr_icon ${state.currentPage === 1 ? 'mlvc_ecb_sc_pr_wr_i_disabled':''}`} onClick={() => handleStepChange('back')}>{Icons.p_left_arrow}</span>
                    <input className="mlvc_ecb_sc_pr_input mlvc_ecb_sc_pr_input_value_1" ref={inputRef} defaultValue={state.currentPage} onChange={onChangePage}/>
                    <span className="mlvc_ecb_sc_pr_divider"></span>
                    <input className="mlvc_ecb_sc_pr_input" readOnly value={Math.ceil((state.totalRecords / state.perPage))}/>
                    <span className={`mlvc_ecb_sc_pr_wr_item mlvc_ecb_sc_pr_icon ${state.currentPage === Math.ceil((state.totalRecords / state.perPage)) ? 'mlvc_ecb_sc_pr_wr_i_disabled':''}`} onClick={() => handleStepChange('next')}>{Icons.p_right_arrow}</span>
                </div>
                }
            </div>
        </div>
    );
}
export default CampaignPagination;