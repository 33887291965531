import { makeStyles, Tab, Tabs } from "@material-ui/core";
import { setCookieCampaign } from "../..";
import CustomMenuPype from "../../components/custom-menu/CustomMenuPype";
import { Icons } from "../../helpers/Icons";
import { handleStates, handleStoreSearchText } from "../../reducer/Actions";
import { useMLv2Context } from "../../reducer/MLV2Provider";
import { useEffect, useState } from "react";
import { fetchTeamMembers } from "../../../../api/marketingApi";
import { CAMPAIGN_OPTION_TYPE, PERMISSION_LEVELS, PERMISSION_MODULES, USER_SELECTOR } from "../../../../constants/CoreConstants";

const downSingleArrow = (
  <svg
    width='21'
    height='22'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M4.7636 6.56341C5.11508 6.21194 5.68492 6.21194 6.0364 6.56341L9 9.52701L11.9636 6.56341C12.3151 6.21194 12.8849 6.21194 13.2364 6.56341C13.5879 6.91488 13.5879 7.48473 13.2364 7.8362L9.6364 11.4362C9.28492 11.7877 8.71508 11.7877 8.3636 11.4362L4.7636 7.8362C4.41213 7.48473 4.41213 6.91488 4.7636 6.56341Z'
      fill='#133159a6'
    />
  </svg>
);

const fold = (<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="m8.9 20-1.4-1.4 4.5-4.5 4.5 4.5-1.4 1.4-3.1-3.1ZM12 9.9 7.5 5.4 8.9 4 12 7.1 15.1 4l1.4 1.4Z" fill="#546376" /></svg>)
const unfold = (<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="m12 21-4.5-4.5 1.45-1.45L12 18.1l3.05-3.05 1.45 1.45ZM8.95 9.05 7.5 7.6 12 3.1l4.5 4.5-1.45 1.45L12 6Z" fill="#546376" /></svg>)

const useStyles = makeStyles((theme) => ({
    tabs: {
        '& .MuiTabs-indicator': {
      backgroundColor: 'var(--light_blue)', 
    },
    },
    tab: {
        fontSize:'15px !important',
        color: 'var(--dark_blue)',
        fontWeight:500,
        textTransform:'capitalize',
        '&:focus': {
          color: 'var(--dark_blue)',
          backgroundColor: 'transparent', 

        },
        '&:hover': {
            color: 'var(--light_blue)',
            backgroundColor: 'transparent',
        },
        '&.Mui-selected': {
         color: 'var(--light_blue)',
          backgroundColor: 'transparent',
        },
      },
      filterDesign: {
        minWidth: '185px !important',
        justifyContent: 'space-between',
        gap:'12px !important'
      }
}));

export const onSelectUser = (item, remove, users, ids, callback) => {
  let tempUsers = [];
  let tempUserIds = [];

  if (item.value === USER_SELECTOR.CLEAR_ALL) {
    tempUserIds = [];
    return;
  }
  if (item.value === USER_SELECTOR.ALL) {
    if (remove) {
      tempUsers = [];
      tempUserIds = [];
    } else {
      tempUsers = [item];
      tempUserIds = [USER_SELECTOR.ALL];
    }
  } else {
    if (ids.length > 0 && ids[0] === USER_SELECTOR.ALL) {
      tempUsers = [item];
      tempUserIds = [item.value];
    } else {
      let old_data_ids = [...ids];
      let old_data_full = [...users];

      if (remove) {
        tempUserIds = old_data_ids.filter(
            (list) => item.value !== list
        );

        tempUsers = old_data_full.filter(
            (list) => item.value !== list.value
        );
      } else {
        old_data_ids.push(item.value);
        old_data_full.push(item);
        tempUsers = old_data_full;
        tempUserIds = old_data_ids;
      }
    }
  }

  callback(tempUsers, tempUserIds);
};
const CampaignListTopBar = ({ className }) => {
    const { state, dispatch } = useMLv2Context();
    const classes = useStyles();
    const [teamlist,setTeamList] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([USER_SELECTOR.ALL]);
    const [selectedUserIds, setSelectedUserIds] = useState([USER_SELECTOR.ALL]);
    const isUserManageGlobalItem = window.hasPermission(PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS, PERMISSION_LEVELS.WRITE);

    useEffect(()=>{
        getTeamUsers();
    },[])
    const handleMinimizeClick = () => {
        dispatch(handleStates({ showMinimizeView: !state.showMinimizeView }))
    }

    const handleStatusFilterChange = (item) => {

        if (item.value === state.selectedStatus.value) {
            return;
        }
        let selectedStatus = {
            "value": item.value,
            "label": item.label,
        };

        dispatch(handleStates({
            selectedStatus: { ...selectedStatus },
            gettingList: true,
            currentPage: 1,
            isCampaignListFiltered:true
        }));

        setCookieCampaign(
            'campaign_filter',
            JSON.stringify({
                search: state.searchText,
                perPage: state.perPage,
                selectedStatus: selectedStatus,
            })
        );
    };
    const handleTabChange = (event, newValue) => {
         dispatch(handleStates({ selectedTabOption: newValue, isCampaignListFiltered:true }));
      };

      const getTeamUsers = async() => {
        const resp = await fetchTeamMembers({});
        if(resp.status === 200 && resp.data.users[0]){
        setTeamList(resp.data.users);
    } else {
        setTeamList([]);
    }
    };

    const handleCreateNewCampaignModal = () => {
        dispatch(handleStates({ isShowCampaignCreate: true }));
      };

      const formattedUsers = teamlist.map((user) => ({ value: user.value, label: user.title }))

      const handleSelectUser = (item, remove) => {
        onSelectUser(item, remove, selectedUsers, selectedUserIds, (tempUsers, tempUserIds) => {
          setSelectedUsers(tempUsers);
          setSelectedUserIds(tempUserIds);
    
          if (tempUserIds[0] === USER_SELECTOR.ALL) {
            tempUserIds = formattedUsers.map((user) => (user.value));
          }
          dispatch(handleStates({selectedUserIds: tempUserIds, isCampaignListFiltered: true }));
        });
      };

    return (
      <div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "40px",
            height: "24px",
            marginBottom: "28px",
          }}
        >
          <div>
            <Tabs
              className={classes.tabs}
              value={state.selectedTabOption}
              onChange={handleTabChange}
              centered
            >
              <Tab className={classes.tab} label="Global Campaigns" />
              <Tab className={classes.tab} label="My Campaigns" />
            </Tabs>
          </div>
        </div>
        <div className={`mlvc_body_container_top_serch main-container ${className}`}>
          <div className="mlvc_ecb_sc_pagination_left">
            <div
              className="mlvc_ecb_sc_top_expand_btn"
              onClick={handleMinimizeClick}
            >
              {state.showMinimizeView ? fold : unfold}
            </div>
            <div style={{position:'relative',width:'250px'}} className="mlvc_body_container_top_serch_left_input_wr">
              {" "}
              <input
                value={state.searchText}
                className="input_focus_box_border_rem mlvc_body_container_tsl_input input-width"
                placeholder="Search"
                onChange={(e) =>
                  dispatch(handleStoreSearchText(e.target.value))
                }
              />
              <span className="mlvc_body_container_tsl_icon">
                {Icons.search_icon}
              </span>
            </div>
          </div>

          <div className="mlvc_body_container_top_serch_left">
            {/* <div style={{width:'175px'}}>
            <GlobalUserListDropdown
            userList={formattedUsers}
            handleSelect={(value, remove) => {
              handleSelectUser(value, remove);
            }}
            selected={selectedUsers}
            selectedId={selectedUserIds}
          />
             
            </div> */}
            <div style={{width:'175px'}}>
              <CustomMenuPype
                wrapperClass="mlvc_row_per_page_dropdown"
                popup={{
                  placement: "left",
                  list: [
                    { value: "ALL", label: "All" },
                    { label: "Running", value: "RUNNING" },
                    { label: "Paused", value: "PAUSED" },
                  ],
                  onClick: (item) => {
                    handleStatusFilterChange(item);
                  },
                  height: "auto",
                  width: "175px",
                  listStyle: {
                    justifyContent: "flex-start",
                    padding: "5px",
                  },
                  optionClass: "campaign_list_dropdown",
                }}
                label={{
                  renderCustom: (
                    <div className="campaign_status_filter_new dropDown-box">
                      <span className="clpb_label">
                        {state.selectedStatus.label}
                      </span>
                      <span className="clpb_icon">{downSingleArrow}</span>
                    </div>
                  ),
                }}
              />
            </div>
            {( isUserManageGlobalItem || (!isUserManageGlobalItem &&
              state.selectedTabOption !== CAMPAIGN_OPTION_TYPE.GLOBAL)
              ) && (
              <div
                className="mlvc_campaign_new_camp_create"
                onClick={handleCreateNewCampaignModal}
              >
                <span style={{ marginTop: "6px" }}>{Icons.plusGray}</span>
                <span>New Campaign</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
}
export default CampaignListTopBar;

